import { get, post } from './index'
// 查询公司详情
export const findCompanyDetails = (p) => {
  return get('/api/company/select', p)
}

// 查询banner图片
export const findBannerImg = (p) => {
  return get('/api/projectGroup/selectById', p)
}
// 查询菜单子项目
export const findItem = (p) => {
  return post('/api/project/selectPage', p)
}
// 查询菜单项目详情
export const findItemDetails = (p) => {
  return get('/api/project/selectById', p)
}
// 案例种类详情
export const findCaseType = (p) => {
  return get('/api/customservice/caseCategory/detail', p)
}

// 查询客户案例详情
export const findCaseDetails = (p) => {
  return get('/api/customservice/customerCase/detail', p)
}
// 查询所有客户案例
export const findMoreCustomerStories = (p) => {
  return get('/api/customservice/customerCase/list', p)
}
// 查询更多解决方案

export const findMoreScheme = (p) => {
  return get('/api/customservice/solution/list', p)
}
// 查询解决方案详情
export const findSchemeDetails = (p) => {
  return get('/api/customservice/solution/detail', p)
}
// // 获取解决方案
export const getSolutionDetails = (p) => {
  return get('/api/customservice/solutionSetting/detail', p)
}
