<template>
  <div class="box">
    <div class="panels">
      <!-- :style="{'background':'url('+panel.imgUrl+')'}" -->
      <div class="panel" v-for="panel in imgList" :key="panel.id">
        <img :src="panel.imgUrl" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  components: {},
  props: {
    imgList: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {}
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.panels {
  width: 100%;
  height: 300px;
  display: flex;
  border-radius: 16px;
  overflow: hidden;
  .panel {
    flex: 1;
    height: 100%;
    cursor: pointer;
    transition: all 0.5s linear;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s linear;
    }
    &:hover {
      flex: 3;
    }
  }
}
</style>
